html,body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  background: #F0F2F4;
}

body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}

footer.footer {
  margin-top: auto;
  padding: 2rem 1.5rem 3rem;
}

.navbar.is-white {
  background: #F0F2F4;
}
.navbar-brand .brand-text {
  font-size: 1.11rem;
  font-weight: bold;
}

.articles {
  margin: 2rem 0;
}
.articles .content p {
    line-height: 1.9;
    margin: 15px 0;
}
.author-image {
    position: absolute;
    top: -30px;
    left: 50%;
    width: 60px;
    height: 60px;
    margin-left: -30px;
    border: 3px solid #ccc;
    border-radius: 50%;
}
.media-center {
  display: block;
  margin-bottom: 1rem;
}
.media-content {
  margin-top: 3rem;
}
.article, .promo-block {
  margin-top: 6rem;
}
div.column.is-8:first-child {
  padding-top: 0;
  margin-top: 0;
}
.article-title {
  font-size: 2rem;
  font-weight: lighter;
  line-height: 2;
}
.article-subtitle {
  color: #909AA0;
  margin-bottom: 3rem;
}
.article-body {
  line-height: 1.4;
  margin: 0 6rem;
}
.promo-block .container {
  margin: 1rem 5rem;
}
.container--main {
  padding-top: 1.5rem;
}

.content {
  margin-top: 1em
}

.content_group {
  margin-top: 3em;
  margin-bottom: 1em;
}

p.inside-href {
  color: #4a4a4a;
}

.content h1.header {
  border-bottom: solid 2px #8e9aaf;
  margin-bottom: 1.5em;
}

.center {
  text-align: center;
}

/* channels */
.add-channel {
  margin-top: 1.5rem;
  padding: 1.5rem;
}

.collection__details {
  background-color: #fff;
  padding: 40px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  color: #4a4a4a;
}

.collection__preview {
  margin-bottom: 1rem;
}

/* hashtags page */
.hashtags__notice.notification {
  margin-bottom: 0.5rem;
}

.hashtags__list {
  margin-bottom: 1em;
}

.hashtags__desc {
  margin-bottom: 1em;
}

.hashtags__btn, .hashtags__btn {
  margin-top: 0.5em;
  font-size: 16px;
}

.small-card-content {
  padding: 1rem;
}

/* convenience styles */
.spacer-little {
  margin-bottom: 20px;
}

.spacer-large {
  margin-bottom: 60px;
}

.is-color1 {
  background-color: #8e9aaf;
}

.is-color2 {
  background-color: #cbc0d3;
}

.is-color3 {
  background-color: #efd3d7;
}

.is-color4 {
  background-color: #feeafa;
}

.is-color5 {
  background-color: #dee2ff;
}
